* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: black;
  font-family: inter;
}

.bt {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* display: block; */
  color: #fff;
  background: linear-gradient( 135deg, #FAB2FF 10%, #1904E5 100%);
  /* padding: 5px 10px; */
  border-radius: 18px;
  min-width: 105px;
  min-height: 36px;
  text-align: center;
  line-height: 36px;
  cursor: pointer;
  text-decoration: none;
}
.bt:hover {
  box-shadow: 0 -10px 20px 0 rgba(82, 207, 242, 0.3);
  background-position: right;
  transform:translate(-50%, -50%) scale(1.05); 
  /* text-decoration: underline; */
}